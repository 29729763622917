<template>
  <div>
    <!--start agb-component-->
    <AGB-component />
    <!--end agb-component-->
  </div>
</template>

<script>
//import components
import AGBComponent from "@/components/AGBComponent.vue";

export default {
  name: "AGB",

  components: { AGBComponent },
};
</script>
